import { render, staticRenderFns } from "./academy_default.vue?vue&type=template&id=8f4dc408&"
import script from "./academy_default.vue?vue&type=script&lang=js&"
export * from "./academy_default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModalConfirmation: require('/home/runner/work/3df/3df/components/Modal/ModalConfirmation/ModalConfirmation.vue').default,ModalImage: require('/home/runner/work/3df/3df/components/Modal/ModalImage/ModalImage.vue').default,ModalForm: require('/home/runner/work/3df/3df/components/Modal/ModalForm/ModalForm.vue').default,ModalPassword: require('/home/runner/work/3df/3df/components/Modal/ModalPassword/ModalPassword.vue').default,ModalForgot: require('/home/runner/work/3df/3df/components/Modal/ModalForgot/ModalForgot.vue').default,ModalLogin: require('/home/runner/work/3df/3df/components/Modal/ModalLogin/ModalLogin.vue').default,ModalRegister: require('/home/runner/work/3df/3df/components/Modal/ModalRegister/ModalRegister.vue').default,ModalFormSet: require('/home/runner/work/3df/3df/components/Modal/ModalFormSet/ModalFormSet.vue').default,Nav: require('/home/runner/work/3df/3df/components/Nav/Nav.vue').default,Menu: require('/home/runner/work/3df/3df/components/Menu/Menu.vue').default,Footer: require('/home/runner/work/3df/3df/components/Footer/Footer.vue').default})
